class Elementor_Image_Default {
    static instance;

    static getInstance() {
        if (!Elementor_Image_Default.instance) {
            Elementor_Image_Default.instance = new Elementor_Image_Default();
        }
        return Elementor_Image_Default.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/image.default', ($scope, $) => {
            if ($scope.hasClass('img-animated')) {
                new Waypoint({
                    element: $scope,
                    offset: '50%',
                    handler: function () {
                        $scope.find('.elementor-image').addClass('img-loaded');
                    }
                });
            }
        });
    }
}

Elementor_Image_Default.getInstance();