class Elementor_Carousel{
    static setupData(data){
        switch (data.navigation){
            case 'both':
                data.dots = true;
                data.nav = true;
                break;
            case 'arrows':
                data.dots = false;
                data.nav = true;
                break;
            case 'dots':
                data.dots = true;
                data.nav = false;
                break;
            case 'none':
                data.dots = false;
                data.nav = false;
                break;
        }
        data.responsive = {
            0: {
                items: data.items_mobile
            },
            768: {
                items: data.items_tablet
            },
            1024:{
                items: data.items
            }
        }

        if(jQuery('body').hasClass('rtl')){
            data.rtl = true;
        }

        return data;
    }
}