class Elementor_Opal_Rev_Slider {
    static instance;

    static getInstance() {
        if (!Elementor_Opal_Rev_Slider.instance) {
            Elementor_Opal_Rev_Slider.instance = new Elementor_Opal_Rev_Slider();
        }
        return Elementor_Opal_Rev_Slider.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-revslider.default', ($scope) => {
            this._vertical_line($scope);
            new Vertical_Line({$element: $scope});

        });

        var Vertical_Line = elementorFrontend.Module.extend({
            onElementChange: function (settingKey) {
                if (0 === settingKey.indexOf('osf_section_vertical_line_switcher') && this.getElementSettings('osf_section_vertical_line_switcher') == 'active') {
                    if (this.$element.find('.vertical-lines').length == 0) {
                        this.$element.find('.slotholder').after('<div class="vertical-lines"><div class="container-wrap"><div class="line-wrap"><div class="line-item line-1"></div><div class="line-item line-2"></div></div></div></div>');
                    }
                }

                if (this.getElementSettings('osf_section_vertical_line_switcher') == '') {
                    if (this.$element.find('.vertical-lines').length > 0) {
                        this.$element.find('.vertical-lines').remove();
                    }
                }
            },
        });
    }

    _vertical_line($scope) {

        var revapi = jQuery(document).ready(function () {
        });

        revapi.one('revolution.slide.onloaded', function () {
            if ($scope.hasClass('osf-vertical-line-active')) {
                $scope.find('.slotholder').after('<div class="vertical-lines"><div class="container-wrap"><div class="line-wrap"><div class="line-item line-1"></div><div class="line-item line-2"></div></div></div></div>');
            }
        });

    }

}

Elementor_Opal_Rev_Slider.getInstance();

