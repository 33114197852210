class Elementor_Timeline {
    static instance;

    static getInstance() {
        if (!Elementor_Timeline.instance) {
            Elementor_Timeline.instance = new Elementor_Timeline();
        }
        return Elementor_Timeline.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
            this.SetUpTimeline();
            jQuery(window).resize(() => {
                this.SetUpTimeline();
            })
        });

    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-timeline.default', ($scope, $) => {

            let $timeline = $scope.find('.opal-timeline-wrapper');
            let nummerTimeline = $timeline.data('timeline-count');

            for (var i = 0; i < nummerTimeline; i++) {
                var premiumImgLaysElem = $scope.find('.timeline-item-' + i);
                $(premiumImgLaysElem).find('.timeline-animation').each(function () {
                    var _this = $(this);
                    if (_this.data('timeline-animation') && ' ' != _this.data('timeline-animation')) {
                        let timeout = _this.data('timeline-animation-delay') * 1000 || 0;
                        _this.css("opacity", "0");

                        var timeline_waypoint = new Waypoint({
                            element: $(premiumImgLaysElem),
                            offset: $.waypoints('viewportHeight') - 150,
                            handler: function () {
                                setTimeout(() => {
                                    _this.css("opacity", "1");
                                }, timeout)
                                _this.addClass("animated " + _this.data('timeline-animation'));
                            }
                        });
                    }
                });
            }

        });
    }

    SetUpTimeline() {
        let timeline = jQuery('.opal-timeline'),
            viewHeight = jQuery(window).height();
        if(timeline.length) {
            let timelinePositionTop = timeline.offset().top,
                timelinePositionBottom = timelinePositionTop + timeline.height();
            jQuery(window).scroll(function(){
                if (jQuery(this).scrollTop() > (timelinePositionTop - (viewHeight/2)) && jQuery(this).scrollTop() <  (timelinePositionBottom - (viewHeight/2)) ) {
                    jQuery('.opal-timeline .timeline-line').css(
                        'height' , (jQuery(this).scrollTop() - timelinePositionTop ) + (viewHeight/2)
                    );
                }if (jQuery(this).scrollTop() <= (timelinePositionTop - (viewHeight/2))) {
                    jQuery('.opal-timeline .timeline-line').css(
                        'height' , 0
                    );
                }
                if (jQuery(this).scrollTop() >=  (timelinePositionBottom - (viewHeight/2))) {
                    jQuery('.opal-timeline .timeline-line').css(
                        'height' , timeline.height()
                    );
                }
                // if((timelinePositionTop - (viewHeight/2)) <= jQuery(this).scrollTop() <= (timelinePositionBottom - (viewHeight/2))) {
                //     jQuery('.opal-timeline .timeline-item').each(function (index, element) {
                //         let position = $(this).offset().top - (viewHeight/2) + ($(this).height()/2);
                //         if(jQuery(window).scrollTop() >= position) {
                //             $(this).addClass('active');
                //         }else {
                //             $(this).removeClass('active');
                //         }
                //     });
                // }

            })


        }
    }
}

Elementor_Timeline.getInstance();