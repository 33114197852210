class Elementor_Slider_Carousel {
    static instance;

    static getInstance() {
        if (!Elementor_Slider_Carousel.instance) {
            Elementor_Slider_Carousel.instance = new Elementor_Slider_Carousel();
        }
        return Elementor_Slider_Carousel.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-slider-box.default', ($scope) => {
            let $navigation = $scope.find('.elementor-slider-box-content-wrapper .elementor-slider-box-item');
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                data.loop = false;
                data.startPosition = 0;
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

            let $itemactive = $scope.find('.owl-item.active .elementor-slider-box-image');
            let $itemhover = $scope.find('.elementor-slider-box-item');

            let $id = $itemactive.data('trigger');
            if ($id) {
                $($id).addClass('active');
            }

            $carousel.on('changed.owl.carousel', function (event) {
                $($itemhover).removeClass('active');

                let current = event.item.index;
                let $id = $(event.target).find(".owl-item").eq(current).find(".elementor-slider-box-image").data('trigger');
                if($id) {
                    $($id).fadeIn().addClass('active');
                }
            });

            $navigation.on('hover', function(){
                let id = $(this).data('index');
                $carousel.trigger('to.owl.carousel', [id])


            })

        });
    }
}


Elementor_Slider_Carousel.getInstance();